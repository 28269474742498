import { derived, writable } from 'svelte/store';

export const selectedSources = writable<string[]>(['montypython', 'simpsons']);

export const numCharacters = writable<number>(5000);

export const numParagraphs = writable<number>(5);

export const useHeaders = writable<boolean>(true);

export const useBulletedLists = writable<boolean>(true);

export const useNumberedLists = writable<boolean>(true);

export const useBoldText = writable<boolean>(true);

export const useItalicText = writable<boolean>(true);

export const useLinks = writable<boolean>(true);

export const renderAsMarkdown = writable<boolean>(false);

export const renderAsHtml = writable<boolean>(false);

export const copyAsMarkup = derived(
	[
		useHeaders,
		useBulletedLists,
		useNumberedLists,
		useBoldText,
		useItalicText,
		useLinks,
		renderAsHtml,
		renderAsMarkdown
	],
	([
		$useHeaders,
		$useBulletedLists,
		$useNumberedLists,
		$useBoldText,
		$useItalicText,
		$useLinks,
		$renderAsHtml,
		$renderAsMarkdown
	]) => {
		// Never return rich text markup if rendering Markdown or HTML to the page
		if ($renderAsHtml || $renderAsMarkdown) return false;

		// If all plain-text, don't bother returning as markup
		return (
			$useHeaders ||
			$useBulletedLists ||
			$useNumberedLists ||
			$useBoldText ||
			$useItalicText ||
			$useLinks
		);
	}
);
